// hello_controller.js
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['type', 'dayOfWeek', 'dateOfEffect']

  initialize () {
    this.#changeInputVisibility()
  }

  changeType () {
    this.dateOfEffectTarget.value = ''
    this.dayOfWeekTarget.value = ''

    this.#changeInputVisibility()
  }

  #changeInputVisibility () {
    switch (this.typeTarget.value) {
      case 'day':
        this.dateOfEffectTarget.parentElement.parentElement.hidden = true
        this.dayOfWeekTarget.parentElement.parentElement.hidden = false
        break
      case 'date':
        this.dateOfEffectTarget.parentElement.parentElement.hidden = false
        this.dayOfWeekTarget.parentElement.parentElement.hidden = true
    }
  }
}
