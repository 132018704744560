// hello_controller.js
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['selectedDate']

  initialize () {
    console.log('Calendar')
  }

  setSelectedDate (event) {
    this.selectedDateTarget.innerHTML = event.target.dataset.currentDate
  }
}
